<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'base.filter' | translate }}

    v-card-content
      v-text-field.mb-3(
        :label="$t('base.name')"
        v-model="form.name")

      .cols-x
        .col-x-2
          v-date-field.mb-3(
            label="base.date_from"
            v-model="form.date_from")
        .col-x-2
          v-date-field.mb-3(
            label="base.date_to"
            v-model="form.date_to")

      v-select.mb-3(
        item-value="id"
        :label="$t('base.ration')"
        :options="rationsList"
        v-model="form.ration_id")

      //- .cols-x
        .col-x-2.mb-3
          v-text-field(
            :label="$t('base.cost_from')"
            v-model="form.cost_from")
        .col-x-2.mb-3
          v-text-field(
            :label="$t('base.cost_to')"
            v-model="form.cost_to")

      v-select.mb-3(
        :label="$t('base.status')"
        item-value="value"
        :options="options.statuses"
        v-model="form.status")

      v-select(
        :label="$t('modals.recipe.filter.in_timetable')"
        item-value="value"
        :options="options.states"
        v-model="form.in_timetable")

    v-card-actions
      v-btn.mr-2(
        :loading="loading"
        @click="onApply")
        | {{ 'actions.apply' | translate }}

      v-btn(
        text
        @click="onReset")
        | {{ 'actions.clear' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FilterRecipesModal',

  mounted () {
    const filters = this.$store.getters.recipesFilters
    for (const key in filters) {
      this.form[key] = filters[key]
    }
    this.fetchRationsList()
  },

  data: () => ({
    form: {
      name: null,
      date_from: null,
      date_to: null,
      ration_id: null,
      // cost_from: null,
      // cost_to: null,
      status: null,
      in_timetable: null
    },
    options: {
      statuses: [
        { name: 'constants.recipe_statuses.applies', value: true },
        { name: 'constants.recipe_statuses.no_applies', value: false }
      ],
      states: [
        { name: 'constants.recipe_states.is_in_planning', value: true },
        { name: 'constants.recipe_states.no_in_planning', value: false }
      ]
    },
    loading: false
  }),

  computed: {
    ...mapGetters(['rationsList'])
  },

  methods: {
    ...mapActions([
      'applyRecipesFilters',
      'fetchRationsList'
    ]),

    async onApply () {
      this.loading = true
      const filters = {}
      for (const key in this.form) {
        if (this.form[key] !== null) {
          filters[key] = this.form[key]
        }
      }
      await this.applyRecipesFilters(filters)
      this.loading = false
      this.$emit('close')
    },

    onReset () {
      for (const key in this.form) {
        this.form[key] = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
